.contact-us-form {
    text-align: center;
}

.contact-us-form input {
    width: 50%;
    margin-bottom: 1%;
}

.contact-us-form textarea {
    width: 50%;
    height: 100px;
}