/*
    contains all basic styling for all calculator pages
    individual calculator styling is in its own stylesheet
*/
.calculate-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.calculator-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 5px;
}

.calculator-select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 5px;
}

.bold-big {
    font-weight: 600;
    font-size: large;
}

.binary-results-text {
    font-weight: 600;
    font-size: large;
}

.bandwidth-data-unit-results {
    text-align: left;
    list-style-type: none;
    justify-content: center;
    margin: auto;
    width: 40%;
    padding-top: 2%;
}

@media (max-width: 1140px) {
    .bandwidth-data-unit-results {
        width: 100%;
    }
}

.bandwidth-data-unit-results-list {
    list-style-type: none;
    width: 100%;
}
