.mean-median-mode-range-calc-container {
    display: flex;
    flex-direction: column;
}

.textarea-label {
    margin-bottom: 8px;
}

.mean-median-mode-range-calc-container textarea {
    margin-bottom: 8px;
    box-sizing: border-box;
    max-width: 600px;
}

@media (max-width: 600px) {
    .mean-median-mode-range-calc-container {
        flex-direction: column;
    }

    .mean-median-mode-range-calc-container label,
    .mean-median-mode-range-calc-container textarea {
        flex: 1;
    }
}