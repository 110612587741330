/* Header.css */
body {
  background: #fff;
  margin: 0px;
  padding: 0px;
  border: 0;
  text-align: center;
}

.center {
  text-align: center;
}

.margin-auto {
  margin: auto;
}

#layout {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  overflow: auto;
  background-color: rgb(240, 245, 245);
  padding: 8px 8px 20px 8px;
}

#content {
  width: 1100px;
  padding: 2% 0 5% 0;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  overflow: auto;
}

.top-slice-container {
  text-align: center;
}

@media (max-width: 1140px) {
  #layout {
    max-width: 900px;
  }

  #content {
      width: 640px;
      padding: 10px;
  }

  .top-slice-container {
    text-align: left;
  }
}

.page {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.text-area-container {
  align-items: center;
  width: 100%;
}

.text-area {
  width: 60%;
}

@media (max-width: 720px) {
  #layout {
    width: auto;
  }

  #content {
      float: none;
      width: auto;
      padding: 0px;
  }

  .top-slice-container {
    text-align: left;
  }
}

.header {
  border-bottom: .1rem solid #e3e6ee;
  display: block;
  height: fit-content;
}

.header-wrapper {
  background: white;
  color: black;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 4rem;
  padding: 0 1rem;
}

.logo {
  margin: auto;
  text-align: left;
}

.logo a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.searchContainer {
  flex: 1 1 25%;
  text-align: right;
}

.header-nav-container {
  flex: 1 1 50%;
  text-align: center;
}

.hamburger-icon {
  display: none;
}

.header-nav a {
  color: black;
  text-decoration: none;
  padding: 1rem;
  margin: auto 3px;
}

.header-nav a:hover {
  color: #0156ffe0;
  background-color: #e5eeff;
  border-bottom: 5px solid #0156ffe0;
  margin: 3px;
}

.search-container.show-search-menu {
  display: flex;
}

.searchInput {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.searchButton {
  padding: 5px 10px;
  background-color: #0156ffe0;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.home-header-links {
  font-size: x-large;
}

.home-header-links a {
  color: black;
}

.home-header-links a:hover {
  color: #0156ffe0;
  text-decoration: underline;
}

.search-container {
  display: none;
  text-align: center;
  flex-direction: column;
  position: absolute;
  top: 65px;
  background-color: lightgray;
  width: 100%;
}

.search-button {
  background: #007bff;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
}

.header-search-input {
  max-width: 750px;
  margin: 10px auto;
  width: 80%;
  padding: 1% 2%;
}

.header-calculator-search {
  list-style-type: none;
  padding: 0;
}

.header-calculator-search a {
  text-decoration: none;
  color: black;
  padding: 2%;
  width: 100%;
}

.header-calculator-list {
  padding: 3px;
}

/* Heder Mobile */
@media only screen and (max-width: 768px) {
  .header-nav-container {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: lightgray;
  }

  .top-slice-container {
    text-align: left;
  }

  .logo {
    flex: 1 1 25%;
  }

  .header-nav-container.show-mobile-menu {
    display: flex;
  }

  .header-nav a {
    margin: 5px;
    padding: 0.5rem;
  }

  .header-link {
    margin: 10px;
    display: block;
    padding: 0.5rem;
  }

  .hamburger-icon {
    font-size: 1.5rem;
    cursor: pointer;
    display: block;
  }
}

.home-top-section {
  flex: 100%;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.breadcrumb-item {
  margin-right: 5px;
}

.link-section {
  flex: 50%;
  box-sizing: border-box;
  padding: 8px;
}

.thinner-page {
  max-width: 900px;
  text-align: center;
  justify-content: center;
  margin: auto;
}

@media (max-width: 767px) {
  .link-section {
    flex: 100%;
  }
}

.info-paragraphs {
  max-width: 650px;
}

@media (min-width: 767px) {
  .link-page-padding {
    padding-left: 20%;
  }
}

.calc-list-item {
  font-size: large;
  padding-bottom: 1rem;
}

.calc-list-item a {
  color: black;
  text-decoration: none;
}

.calc-list-item a:hover {
  color: #0156ffe0;
  text-decoration: underline;
}

.tab-container {
  display: flex;
  justify-content: center;
}

.tab-content-container {
  display: flex;
  justify-content: center;
}

.tab-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px 15px;
  cursor: pointer;
  outline: none;
  border-radius: 5px 5px 0 0;
}

.tab-button:hover {
  background-color: #e0e0e0;
}

.tab-button.active {
  background-color: #fff;
  border-bottom: 2px solid #007bff;
  border-radius: 5px 5px 0 0;
}

.tab-content {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
  margin-top: -1px;
  width: 100%;
  max-width: 800px;
}

/* Footer */
.footer {
  border-top: #ccc 5px;
  font-size: 15px;
  margin: 2rem 0 3rem 0;
}

.footer-nav-container {
  flex: 1 1 50%;
  text-align: center;
}

.footer-nav a {
  color: black;
  padding: .5rem;
  margin: auto 3px;
}

@media (min-width: 719px) {
  .footer-nav a:hover {
    color: #0156ffe0;
    background-color: #e5eeff;
    border-bottom: 5px solid #0156ffe0;
    margin: 3px;
  }
}
